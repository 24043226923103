import {customElement} from "lit/decorators.js";
import {EventBus} from "../../../common/eventBus";
import {resolve} from "../../../container";
import {QueryParameters} from "../../../common/queryParameters";
import {ScrollService} from "../../../common/scroll";
import {NEW_SEARCH_PHRASE_EVENT, NEWS_TAG_EVENT, SEARCH_QUERY_PARAMETER_NAME} from "./constants";
import type {PropertyValues} from "lit";
import Styles from "./contentSearchSlot.lit.scss";
import {SearchSlotWithAutocomplete} from "./searchSlotWithAutocomplete";


@customElement("eop-content-search-slot")
export class EopContentSearchSlot extends SearchSlotWithAutocomplete {

    public static readonly styles = Styles;

    public constructor(
        private eventBus: EventBus = resolve(EventBus),
        private queryParameters: QueryParameters = resolve(QueryParameters),
        private scrollService: ScrollService = resolve(ScrollService)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.eventBus.on(NEW_SEARCH_PHRASE_EVENT, event => {
            this.input.value = event.data.searchPhrase;
            this.queryParameters.set(SEARCH_QUERY_PARAMETER_NAME, this.input.value);
        }, this);

        this.eventBus.on(NEWS_TAG_EVENT, event => {
            this.input.value = event.data.text;
            this.triggerSearch();
            void this.scrollService.scrollToElement(this);
        }, this);
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);
        this.searchFacade.registerFilterInitialization(async (sf) => {
            this.queryParameters.onString(SEARCH_QUERY_PARAMETER_NAME, searchPhrase => {
                this.input.value = searchPhrase;
                this.updateResetButtonVisibility();
                sf.initSearchPhrase(searchPhrase);
            });
            return sf;
        });
    }

    protected triggerSearch(): void {
        this.cancelPendingInput();
        this.closeSuggestions();
        this.eventBus.dispatchEvent(NEW_SEARCH_PHRASE_EVENT, {searchPhrase: this.input.value});
    }

    protected afterReset(): void {
        this.triggerSearch();
    }
}