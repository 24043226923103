import {ANIMATION_DURATION, GridLayout, GridLayoutFactory} from "../../common/gridLayout";
import {ScrollService} from "../../common/scroll";
import {CONFIRMABLE_CHANNELS, ICONS} from "./feedCommons";
import {resolve} from "../../container";
import {Timeout} from "../../common/timeout";
import {LocatedDates} from "../../common/utils/dates/locatedDates";
import {TemplateModel} from "../../common/template";
import {schedule} from "../../common/utils/promises";
import {html, LitElement, type PropertyValues, type TemplateResult} from "lit";
import {customElement, property, query, state} from "lit/decorators.js";
import StylesTile from "./socialWallTile.lit.scss";
import Styles from "./socialWall.lit.scss";
import {unsafeHTML} from "lit/directives/unsafe-html.js";
import type {DirectiveResult} from "lit/directive.js";
import {Initializing} from "../../common/elements";
import {DefaultFeedSource} from "./feedSource";
import {FeedEntry, type FeedEntryData} from "./feedEntry";
import type {EopOverlaySpinner} from "../../page/elements/spinner";
import {ManagingResources} from "../../common/lifetime";
import {IntersectionObserverFactory, onceIntersected} from "../../common/observation";


@customElement("eop-social-wall")
export class EopSocialWall extends Initializing(ManagingResources(LitElement)) {

    public static readonly styles = Styles;

    @state()
    private feedEntries: FeedEntry[] = [];
    @property({attribute: "number", type: Number})
    private limit: number = 3;
    @property({attribute: "fetch-more-amount", type: Number})
    private fetchMoreAmount: number = 0;

    @query(".social-wall-tiles")
    private tilesContainer: HTMLElement;
    @query("eop-overlay-spinner")
    private overlaySpinner: EopOverlaySpinner;

    private gridLayout: GridLayout;
    private tilesContainerObserver: IntersectionObserver;

    public constructor(
        private feedSource: DefaultFeedSource = new DefaultFeedSource(),
        private timeout: Timeout = resolve(Timeout),
        private scrollService: ScrollService = resolve(ScrollService),
        private gridLayoutFactory: GridLayoutFactory = resolve(GridLayoutFactory),
        private intersectionObserverFactory: IntersectionObserverFactory = resolve(IntersectionObserverFactory)
    ) {
        super();
    }

    public render(): TemplateResult {
        return html`
            <eop-overlay-spinner></eop-overlay-spinner>
            <div class="social-wall-tiles">
                <div class="small-tile grid-element grid-reference-element"></div>
                ${this.renderTiles()}
            </div>
            ${this.renderFetchMoreButton()}
        `;
    }

    private renderTiles(): TemplateResult[] | null {
        if (this.feedEntries.isEmpty()) {
            return null;
        }
        return this.feedEntries.map(entry => html`
            <eop-social-wall-tile .entry=${entry}></eop-social-wall-tile>
        `);
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.feedSource.configure({
            sources: this.getAttribute("sources")?.parseAsJSON() ?? {},
            keywords: this.getAttribute("keywords")?.parseCommaSeparated() ?? [],
            excludedKeywords: this.getAttribute("excluded-keywords")?.parseCommaSeparated() ?? [],
            characterLimit: this.getAttribute("character-limit")?.toInt() ?? 180,
            language: this.getAttribute("language") ?? ""
        });
        this.tilesContainerObserver = this.intersectionObserverFactory.create(onceIntersected(() => this.scheduleLoadingTiles()));
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);
        this.tilesContainerObserver.observe(this.tilesContainer);
    }

    private scheduleLoadingTiles(): void {
        schedule(this.overlaySpinner.spinWhile(this.fetchTiles(this.limit, 0)
            .then(() => {
                this.initialized();
                this.gridLayout = this.gridLayoutFactory.create(this.tilesContainer, ".grid-element", ".grid-reference-element");
                this.gridLayout.initLayout();
            }))).as("fetch");
    }

    private renderFetchMoreButton(): TemplateResult | null {
        if (this.showFetchMore()) {
            return html`
                <div class="fetch-more-button">
                    <button data-target="button" type="button" class="primary" data-eventelement="button" @click=${this.fetchMore}>
                        <eop-msg key="MSG_TEASER_FETCH_MORE"></eop-msg>
                    </button>
                </div>
            `;
        }
        return null;
    }

    private async fetchMore(): Promise<void> {
        const offset = this.feedEntries.length;
        await schedule(this.overlaySpinner.spinWhile(this.fetchTiles(this.fetchMoreAmount, offset)
            .then(() => this.scrollToNextElement(offset))))
            .as("fetch-more");
    }

    private scrollToNextElement(offset: number): void {
        this.timeout.delay(() => {
            const nextTile = this.renderRoot.querySelectorAll<EopSocialWall>(".social-wall-tile").item(offset);
            this.scrollService.scrollToElement(this.tilesContainer, -parseInt(nextTile.style.top), ANIMATION_DURATION);
        });
    }

    private showFetchMore(): boolean {
        return this.fetchMoreAmount > 0
            && this.feedSource.total() > this.feedEntries.length;
    }

    private async fetchTiles(size: number, offset: number): Promise<FeedEntry[]> {
        const entries = await this.feedSource.fetch(size, offset);
        const newlyAddedTiles = entries.map(entry => {
            return FeedEntry.fromResponse(entry)
                .inChannelContext(this.feedSource.channels());
        });
        this.feedEntries = this.feedEntries.slice(0, offset).concat(newlyAddedTiles);
        return newlyAddedTiles;
    }
}

@customElement("eop-social-wall-tile")
export class EopSocialWallTile extends LitElement {

    public static readonly styles = StylesTile;

    @property()
    public entry: FeedEntry;

    private data: FeedEntryData;

    public constructor(
        private locatedDates: LocatedDates = resolve(LocatedDates)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.data = this.entry.data;
        this.classList.add("social-wall-tile", "grid-element", this.entry.getTileStyle());
    }

    public render(): TemplateResult {
        return this.needsConfirmation()
            ? this.renderConfirmableTile()
            : this.renderTile();
    }

    private needsConfirmation(): boolean {
        return !!this.data.contentType && CONFIRMABLE_CHANNELS.includes(this.data.contentType);
    }

    private renderConfirmableTile(): TemplateResult {
        return html`
            <eop-load-after-confirmation
                    module-name="youtube"
                    confirmation-text-key="YOUTUBE_CONFIRMATION_TEXT"
                    button-label-key="YOUTUBE_CONFIRMATION_BUTTON_LABEL"
            >
                <span slot="content-to-confirm">
                    ${this.renderTile()}
                </span>
            </eop-load-after-confirmation>
        `;
    }

    private renderTile(): TemplateResult {
        const trackingLabel = this.data.headline || `social-wall-tile-${this.data.contentType}`;
        return html`
            <a href=${this.data.url} target="_blank" class="social-wall-entry" data-tracking-label=${trackingLabel}>
                <div class="tile-media ${this.entry.getTileStyle()}">${this.imageElement()}</div>
                <div class="tile-text">
                    ${this.iconElement()}
                    <div class="metadata">${this.metaData()}</div>
                    <div class="tile-headline">${this.headlineText()}</div>
                    <div class="description-text">${this.descriptionText()}</div>
                </div>
            </a>`;
    }

    private headlineText(): DirectiveResult | string {
        return this.data.headline ? unsafeHTML(this.data.headline) : "";
    }

    private descriptionText(): DirectiveResult | string {
        return this.data.subHeadline ? unsafeHTML(this.data.subHeadline) : "";
    }

    private imageElement(): TemplateResult | Text {
        if (this.data.contentType === "youtube") {
            return html`
                <eop-confirmable-iframe-element src=${this.entry.getImageUrl()}
                                                module-name="youtube"
                                                .additionalClasses=${this.classesToCenterYoutube()}>
                </eop-confirmable-iframe-element>`;
        }
        switch (this.data.mediaType) {
            case "responsiveImage": {
                return html`
                    <eop-responsive-image image-src=${this.entry.getImageUrl()}
                                          image-alt=${this.data.imageAltText ?? ""}
                                          intrinsic-aspect-ratio="true">
                    </eop-responsive-image>`;
            }
            case "image": {
                return html`
                    <eop-plain-image src=${this.entry.getImageUrl()}
                                     alt=${this.data.imageAltText ?? ""}
                                     class="plain-image-container eop-image">
                    </eop-plain-image>`;
            }
            case "video":
            case "none":
            default: {
                return document.createTextNode("");
            }
        }
    }


    private classesToCenterYoutube(): Record<string, boolean> {
        return {
            "youtube-centered-vertically": true,
            "double-tile": this.entry.getTileStyle() === "double-tile"
        };
    }

    private iconElement(): Element | Text | undefined {
        const icon = this.data.contentType ? ICONS[this.data.contentType] : null;
        if (!icon) {
            return document.createTextNode("");
        }
        return new TemplateModel(icon)
            .addClasses("tile-icon")
            .asElement();
    }

    private metaData(): string {
        const date = this.locatedDates.toLocalDateString(new Date(this.data.publishedDate));
        if (this.data.principalKeyword) {
            return `${date} | ${this.data.principalKeyword}`;
        } else {
            return `${date}`;
        }
    }
}