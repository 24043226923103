import {BOOTSTRAP} from "../../common/resolutionConstants";
import type {Resolution} from "../../common/resolution";

const ROW_SIZE_XS = 1;
const ROW_SIZE_SM = 2;
const ROW_SIZE_MD_TO_XL = 3;
const ROW_SIZE_XXL_PLUS = 4;

// Grid
const AMOUNTS_MULTI_ROW: AmountsMultiRow = {
    small: {
        listOnMobile: {xs: 4, sm: 4, mdToXl: ROW_SIZE_MD_TO_XL, xxlPlus: ROW_SIZE_XXL_PLUS},
        alwaysTiles: {xs: 3 * ROW_SIZE_XS, sm: 2 * ROW_SIZE_SM, mdToXl: ROW_SIZE_MD_TO_XL, xxlPlus: ROW_SIZE_XXL_PLUS}
    },
    medium: {
        listOnMobile: {xs: 6, sm: 6, mdToXl: 2 * ROW_SIZE_MD_TO_XL, xxlPlus: 2 * ROW_SIZE_XXL_PLUS},
        alwaysTiles: {xs: 4 * ROW_SIZE_XS, sm: 3 * ROW_SIZE_SM, mdToXl: 2 * ROW_SIZE_MD_TO_XL, xxlPlus: 2 * ROW_SIZE_XXL_PLUS}
    },
    large: {
        listOnMobile: {xs: 8, sm: 8, mdToXl: 3 * ROW_SIZE_MD_TO_XL, xxlPlus: 3 * ROW_SIZE_XXL_PLUS},
        alwaysTiles: {xs: 6 * ROW_SIZE_XS, sm: 4 * ROW_SIZE_SM, mdToXl: 3 * ROW_SIZE_MD_TO_XL, xxlPlus: 3 * ROW_SIZE_XXL_PLUS}
    },
    max: {
        listOnMobile: {xs: 30, sm: 30, mdToXl: 30, xxlPlus: 30},
        alwaysTiles: {xs: 30, sm: 30, mdToXl: 30, xxlPlus: 30}
    }
};

// Slider
const AMOUNTS_SINGLE_ROW: AmountsSingleRow = {
    small: {xs: 3, sm: 4, mdToXl: 5, xxlPlus: 6},
    medium: {xs: 5, sm: 6, mdToXl: 8, xxlPlus: 10},
    large: {xs: 7, sm: 8, mdToXl: 12, xxlPlus: 15},
    max: {xs: 30, sm: 30, mdToXl: 30, xxlPlus: 30}
};

type AmountsMultiRow = {
    [size in SizeName]: {
        [arrangement in ArrangementName]: AmountsPerBreakpoint;
    };
};

type AmountsSingleRow = {
    [size in SizeName]: AmountsPerBreakpoint;
};

type AmountsPerBreakpoint = {
    [breakpoint in BreakpointKey]: number;
};

type BreakpointKey = "xs" | "sm" | "mdToXl" | "xxlPlus"
type ArrangementName = "listOnMobile" | "alwaysTiles";
type SizeName = "small" | "medium" | "large" | "max";

class Size {
    public static readonly SMALL = new Size("small");
    public static readonly MEDIUM = new Size("medium");
    public static readonly LARGE = new Size("large");
    public static readonly MAX = new Size("max");

    private constructor(public readonly name: SizeName) {
    }

    public static all(): Size[] {
        return [Size.SMALL, Size.MEDIUM, Size.LARGE, Size.MAX];
    }

    public static parseWithDefault(name: string | undefined, defaultValue: Size): Size {
        return this.all()
                .findFirst(size => size.name === name)
            ?? defaultValue;
    }
}

class Arrangement {
    public static readonly MOBILE_LIST = new Arrangement("listOnMobile");
    public static readonly TILES_ONLY = new Arrangement("alwaysTiles");

    private constructor(public readonly name: ArrangementName) {
    }

    public static parse(rawArrangement: string | undefined): Arrangement {
        return rawArrangement === "mobile-condensed"
            ? Arrangement.MOBILE_LIST
            : Arrangement.TILES_ONLY;
    }
}

class BreakpointRange {
    public static readonly PHONE = new BreakpointRange("xs");
    public static readonly TABLET_PORTRAIT = new BreakpointRange("sm");
    public static readonly UP_TO_DESKTOP_LARGE = new BreakpointRange("mdToXl");
    public static readonly FROM_DESKTOP_ULTRA_WIDE = new BreakpointRange("xxlPlus");

    private constructor(public readonly name: BreakpointKey) {
    }

    public static getCurrent(resolution: Resolution): BreakpointRange {
        if (resolution.upTo(BOOTSTRAP.XS)) {
            return BreakpointRange.PHONE;
        }
        if (resolution.upTo(BOOTSTRAP.SM)) {
            return BreakpointRange.TABLET_PORTRAIT;
        }
        if (resolution.upTo(BOOTSTRAP.XL)) {
            return BreakpointRange.UP_TO_DESKTOP_LARGE;
        }
        return BreakpointRange.FROM_DESKTOP_ULTRA_WIDE;
    }
}

export class MultiRowFeedPreviewSize {
    private amounts: AmountsPerBreakpoint;

    public constructor(rawSize: string | undefined, rawArrangement: string | undefined, private resolution: Resolution) {
        const size = Size.parseWithDefault(rawSize, Size.SMALL);
        const arrangement = Arrangement.parse(rawArrangement);
        this.amounts = AMOUNTS_MULTI_ROW[size.name][arrangement.name];
    }

    public getTileAmount(): number {
        return this.amounts[BreakpointRange.getCurrent(this.resolution).name];
    }
}

export class SingleRowFeedPreviewSize {
    private amounts: AmountsPerBreakpoint;

    public constructor(rawSize: string | undefined, private resolution: Resolution) {
        const size = Size.parseWithDefault(rawSize, Size.SMALL);
        this.amounts = AMOUNTS_SINGLE_ROW[size.name];
    }

    public getTileAmount(): number {
        return this.amounts[BreakpointRange.getCurrent(this.resolution).name];
    }
}