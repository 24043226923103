import {customElement, property, state} from "lit/decorators.js";
import {html, LitElement, type TemplateResult} from "lit";
import {Dictionary} from "../../../page/elements/dictionary";
import {type ContactsResponse, ContactsService} from "../contactsService";
import {resolve} from "../../../container";
import {ContactsRequest} from "../requests";
import Styles from "./newsSearchContacts.lit.scss";
import {unsafeHTML} from "lit/directives/unsafe-html.js";
import {EopImageSpinner} from "../../../page/elements/spinner";
import {keyed} from "lit/directives/keyed.js";
import type {DirectiveResult} from "lit/directive.js";
import {LanguagesService} from "../../../common/languages";
import {SearchFacade} from "./searchFacade";
import {until} from "lit/directives/until.js";
import {templateContent} from "lit/directives/template-content.js";
import {Deferred, EOP_ERRORS, schedule} from "../../../common/utils/promises";


export type ContactData = {
    title: string;
    email: string;
    info: string;
    link: string;
    imageUrl?: string;
    corporationName?: string;
    phone?: string;
    fax?: string;
    mobile?: string;
    city?: string;
    street?: string;
};

@customElement("eop-news-search-contacts")
export class EopNewsSearchContacts extends LitElement {

    public static readonly styles = Styles;

    @state()
    private asyncContacts: Promise<TemplateResult | void>;
    @property({attribute: "contact-mode"})
    private contactMode: string;

    private dictionary: Dictionary;
    private defaultContact: DirectiveResult;
    private template: TemplateResult | null;

    public constructor(
        private contactsService: ContactsService = resolve(ContactsService),
        private languages: LanguagesService = resolve(LanguagesService),
        private searchFacade: SearchFacade = resolve(SearchFacade)
    ) {
        super();
        this.asyncContacts = new Deferred<void>().promise;
        this.template = null;
    }

    public render(): TemplateResult | null {
        return this.template;
    }

    public connectedCallback(): void {
        super.connectedCallback();
        if (this.contactMode === "no_contact") {
            return;
        }
        this.dictionary = Dictionary.of(this);
        this.defaultContact = templateContent(this.querySelector(".default-contact-template")!);

        this.searchFacade.onNewSearchPhrase(searchPhrase => {
            const request = new ContactsRequest(this.languages.activeLanguageId(), searchPhrase);
            this.asyncContacts = this.contactsService.contacts(request)
                .then(response => this.renderContactsElement(this.mapContacts(response)))
                .catch(EOP_ERRORS);
            schedule(this.asyncContacts).as("contacts");
            this.template = html`
                ${until(this.asyncContacts, new EopImageSpinner())}
            `;
        });
    }

    private renderContactsElement(contacts: ContactData[]): TemplateResult {
        if (contacts.isEmpty()) {
            return html`${this.defaultContact}`;
        } else {
            return html`${(this.renderBusinessCards(contacts))}`;
        }
    }

    private renderBusinessCards(contacts: ContactData[]): TemplateResult[] {
        return contacts.map(contact => html`
            <div class=business-card>
                ${this.image(contact)}
                <div class=business-card-text>
                    <div class=business-card-title>${contact.title}</div>
                    <div class=business-card-position>${unsafeHTML(contact.info)}</div>
                    ${this.renderAddressSection(contact)}
                    ${this.renderContactSection(contact)}
                    <a class="link email-link business-card-email" href="mailto:${(contact.email)}">${contact.email}</a>
                    <div>${unsafeHTML(contact.link)}</div>
                </div>
            </div>
        `);
    }

    private renderContactSection(contact: ContactData): TemplateResult | null {
        if (!contact.phone && !contact.fax && !contact.mobile) {
            return null;
        }

        return html`
            <div class=business-card-contact>
                ${this.renderPhone(contact)}
                ${this.renderFax(contact)}
                ${this.renderMobileNumber(contact)}
            </div>
        `;
    }

    private renderAddressSection(contact: ContactData): TemplateResult | null {
        if (!contact.corporationName && !contact.street && !contact.city) {
            return null;
        }

        return html`
            <div class=business-card-address>
                <div>${contact.corporationName}</div>
                <div>${contact.street}</div>
                <div>${contact.city}</div>
            </div>
        `;
    }

    private image(contact: ContactData): DirectiveResult | null {
        return contact.imageUrl ? keyed(contact.imageUrl, html`
            <div class=business-card-image>
                <eop-responsive-image
                        .imageSrc=${contact.imageUrl}
                        .imageAlt=${contact.title}
                >
                </eop-responsive-image>
            </div>
        `) : null;
    }

    private renderPhone(contact: ContactData): TemplateResult | null {
        if (!contact.phone) {
            return null;
        }

        return html`
            ${this.dictionary.translate("CONTENT_LABEL_PHONE")}
            <span><a class="link tel-link" href="tel:${contact.phone}">${contact.phone}</a></span>
        `;
    }

    private renderFax(contact: ContactData): TemplateResult | null {
        if (!contact.fax) {
            return null;
        }

        return html`
            ${this.dictionary.translate("CONTENT_LABEL_FAX")}
            <span><a class="link fax-link" href="tel:${contact.fax}">${contact.fax}</a></span>
        `;
    }

    private renderMobileNumber(contact: ContactData): TemplateResult | null {
        if (!contact.mobile) {
            return null;
        }

        return html`
            ${this.dictionary.translate("CONTENT_LABEL_MOBILE")}
            <span><a class="link tel-link" href="tel:${contact.mobile}">${contact.mobile}</a></span>
        `;
    }

    private mapContacts(response: ContactsResponse): ContactData[] {
        return response.contacts.map(contact => ({
            title: contact.title,
            imageUrl: contact.imageUrl,
            email: contact.contactInfo.email,
            phone: contact.contactInfo.phone,
            fax: contact.contactInfo.fax,
            mobile: contact.contactInfo.mobile,
            info: contact.infoBlock,
            city: contact.address.city,
            street: contact.address.street,
            postalCode: contact.address.postalCode,
            link: contact.link,
            corporationName: contact.corporationName
        }));
    }
}