import {DefaultFeedSource} from "../feedSource";
import {customElement, property} from "lit/decorators.js";
import {AbstractFeedPreviewGrid, AbstractFeedPreviewSlider} from "./feedPreviewCommons";

@customElement("eop-feed-preview")
export class EopFeedPreview extends AbstractFeedPreviewGrid<DefaultFeedSource> {

    @property({attribute: "language"})
    public language: string = "";
    @property({attribute: "sources"})
    public sources: string;
    @property({attribute: "keywords"})
    public keywords: string;
    @property({attribute: "excluded-keywords"})
    public excludedKeywords: string;
    @property({attribute: "show-more", type: Boolean})
    public enableMore: boolean = false;

    public constructor(feedSource: DefaultFeedSource = new DefaultFeedSource()) {
        super(feedSource);
    }

    protected configure(): void {
        this.feedSource.configure({
            sources: this.sources?.parseAsJSON() ?? {},
            keywords: this.keywords?.parseCommaSeparated() ?? [],
            excludedKeywords: this.excludedKeywords?.parseCommaSeparated() ?? [],
            characterLimit: this.characterLimit,
            language: this.language
        });
    }
}

@customElement("eop-feed-preview-slider")
export class EopFeedPreviewSlider extends AbstractFeedPreviewSlider<DefaultFeedSource> {

    @property({attribute: "language"})
    public language: string = "";
    @property({attribute: "sources"})
    public sources: string;
    @property({attribute: "keywords"})
    public keywords: string;
    @property({attribute: "excluded-keywords"})
    public excludedKeywords: string;

    public constructor(feedSource: DefaultFeedSource = new DefaultFeedSource()) {
        super(feedSource);
    }

    protected configure(): void {
        this.feedSource.configure({
            sources: this.sources?.parseAsJSON() ?? {},
            keywords: this.keywords?.parseCommaSeparated() ?? [],
            excludedKeywords: this.excludedKeywords?.parseCommaSeparated() ?? [],
            characterLimit: this.characterLimit,
            language: this.language
        });
    }
}