import {Search} from "./search";
import {property, query} from "lit/decorators.js";
import {IntersectionViewportObserver} from "./intersectionViewportObserver";
import {resolve} from "../../../container";
import type {PropertyValues} from "lit";
import {SEARCH_TILES_IN_VIEWPORT_EVENT} from "./constants";


export abstract class FilteredSearch extends Search {
    @property({attribute: "target-url"})
    protected targetUrl: string;
    @property({attribute: "tags", type: Array})
    protected tags: string[];
    @query(".tiles")
    protected searchTiles: HTMLElement;
    @query(".footer")
    protected footer: HTMLElement;

    protected constructor(
        emptyOnEmptySearchPhrase: boolean,
        protected viewport: IntersectionViewportObserver = resolve(IntersectionViewportObserver)
    ) {
        super(emptyOnEmptySearchPhrase);
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);

        if (this.searchTiles && this.footer) {
            const observation = this.viewport.observe()
                .boundTo(this)
                .onChange(inViewport => this.eventBus.dispatchEvent(SEARCH_TILES_IN_VIEWPORT_EVENT, {inViewport: inViewport}));
            observation
                .main(this.searchTiles)
                .footer(this.footer);
        }
    }
}