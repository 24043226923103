import {UrlBuilder} from "../../../common/utils/url";
import {Nav} from "../../../common/nav";
import {resolve} from "../../../container";
import {SEARCH_QUERY_PARAMETER_NAME} from "./constants";
import {customElement, property} from "lit/decorators.js";
import Styles from "./newsSearchSlot.lit.scss";
import {SearchSlotWithAutocomplete} from "./searchSlotWithAutocomplete";


@customElement("eop-news-search-slot")
export class EopNewsSearchSlot extends SearchSlotWithAutocomplete {

    public static readonly styles = Styles;

    @property({attribute: "target-url"})
    private targetUrl: string;

    public constructor(private nav: Nav = resolve(Nav)) {
        super();
    }

    protected triggerSearch(): void {
        if (this.targetUrl === "") {
            return;
        }
        const url = new UrlBuilder(this.targetUrl)
            .withQueryParameter(SEARCH_QUERY_PARAMETER_NAME, this.input.value)
            .build();
        this.nav.redirect(url);
    }

    protected afterReset(): void {
    }
}