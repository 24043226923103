import type {SearchGroup} from "./searchGroup";
import {DefaultSearchGroup} from "./searchGroup";
import {Deferred, EOP_ERRORS, Promises, schedule} from "../../../common/utils/promises";
import {autoRegister, resolve} from "../../../container";
import {isNumber} from "../../../bootstrap/common/numbers";
import isEqual from "lodash.isequal";
import type {SearchBackend} from "./searchBackend";
import {EMPTY_PAGE_RESPONSE, type FilterDataResponse, type PageResponse} from "../feedService";

type UpdateFilterCallback = (applicableData: SearchFilterData[], totalCount: number) => void;
type InitFilterCallback = (applicableData: SearchFilterData[]) => void;
type UpdateEntryCountCallback = (entryCount: number) => void;
type ChangeGroupCallback = (group: SearchGroup) => void;
type ApplyFilterCallback = (filter: Filters) => void;
type NewEntriesCallback = (group: SearchGroup) => void;
type NewSearchPhraseCallback = (searchPhrase: string) => void;

const NO_FILTERS: Filters = new Map();

export type Filters = Map<string, string[]>;

export type SearchFilterData = {
    id: string;
    label: string;
    filters: string[];
};

@autoRegister()
export class SearchFacade {
    public lang: string;
    public charactersLimit: number;
    public archived: boolean;
    private entriesPerPage: number;
    private emptyOnEmptySearchPhrase: boolean;

    private searchGroups: SearchGroup[];
    private searchPhrase: string;
    private allFilterData: SearchFilterData[] | undefined;
    private chosenFilters: Filters;
    private selectedFilters: Filters;
    private backendResolver: Deferred<SearchBackend>;
    private backend: Promise<SearchBackend>;
    private groupInit: Promise<SearchFacade>;
    private filterInit: Promise<SearchFacade>;

    private initFilterCallbacks: InitFilterCallback[];
    private updateFilterCallbacks: UpdateFilterCallback[];
    private updateEntryCountCallbacks: UpdateEntryCountCallback[];
    private changeGroupCallbacks: ChangeGroupCallback[];
    private applyFilterCallbacks: ApplyFilterCallback[];
    private newEntriesCallbacks: NewEntriesCallback[];
    private newSearchPhraseCallbacks: NewSearchPhraseCallback[];

    public constructor(private promises: Promises = resolve(Promises)) {
        this.searchGroups = [];
        this.searchPhrase = "";
        this.chosenFilters = new Map();
        this.selectedFilters = new Map();
        this.lang = "";
        this.entriesPerPage = 0;
        this.charactersLimit = 180;
        this.archived = false;

        this.initFilterCallbacks = [];
        this.updateFilterCallbacks = [];
        this.updateEntryCountCallbacks = [];
        this.changeGroupCallbacks = [];
        this.applyFilterCallbacks = [];
        this.newEntriesCallbacks = [];
        this.newSearchPhraseCallbacks = [];
        this.backendResolver = new Deferred();
        this.backend = this.backendResolver.promise;
        this.groupInit = Promise.resolve(this);
        this.filterInit = Promise.resolve(this);
    }

    public registerBackend(backend: SearchBackend): void {
        this.backendResolver.resolve(backend);
    }

    public registerGroupInitialization(next: (searchFacade: SearchFacade) => Promise<SearchFacade>): void {
        this.groupInit = this.groupInit.then(next);
    }

    public registerFilterInitialization(next: (searchFacade: SearchFacade) => Promise<SearchFacade>): void {
        this.filterInit = this.filterInit.then(next);
    }

    public configure(lang: string, entriesPerPage: number, charactersLimit: number, archived: boolean, emptyOnEmptySearchPhrase: boolean): void {
        this.lang = lang;
        this.entriesPerPage = entriesPerPage;
        this.charactersLimit = charactersLimit;
        this.archived = archived;
        this.emptyOnEmptySearchPhrase = emptyOnEmptySearchPhrase;
    }

    protected async fetchEntries(sources: string[], searchPhrase: string, limit: number, offset: number, filters: Filters): Promise<PageResponse> {
        const backend = await this.backend;
        return backend.fetchEntries(this, sources, searchPhrase, limit, offset, filters);
    }

    protected async fetchResultsCount(sources: string[], searchPhrase: string, filters: Filters): Promise<number> {
        const backend = await this.backend;
        return backend.fetchResultsCount(this, sources, searchPhrase, filters);
    }

    protected async fetchFilterData(sources: string[]): Promise<FilterDataResponse[]> {
        const backend = await this.backend;
        return backend.fetchFilterData(this, sources);
    }

    public async initialization(): Promise<void> {
        if (!this.hasSearchGroups()) {
            await this.groupInit;
        }
        if (!this.hasSearchGroups()) {
            const group = new DefaultSearchGroup("default", {});
            this.searchGroups.push(group);
        }
        if (!this.activeGroup()) {
            this.initWith(0);
        }
        if (!this.allFilterData) {
            await this.filterInit;
        }
        if (!this.allFilterData) {
            this.allFilterData = [];
        }
    }

    public async fetchFilters(): Promise<void[]> {
        const fetchFiltersPromises: Promise<void>[] = [];

        fetchFiltersPromises.push(...this.updateFilters());
        fetchFiltersPromises.push(this.initFilters());
        return Promise.all(fetchFiltersPromises);
    }

    public onInitFilter(initFilter: (data: SearchFilterData[]) => void): void {
        this.initFilterCallbacks.push(initFilter);
    }

    public notifyInitFilter(searchFilterData: SearchFilterData[]): void {
        this.initFilterCallbacks.forEach(callback => callback(searchFilterData));
    }

    public onUpdateFilter(updateFilter: UpdateFilterCallback): void {
        this.updateFilterCallbacks.push(updateFilter);
    }

    public notifyUpdateFilter(applicableData: SearchFilterData[], totalCount: number): void {
        this.updateFilterCallbacks.forEach(callback => callback(applicableData, totalCount));
    }

    public onUpdateEntryCount(updateEntryCount: UpdateEntryCountCallback): void {
        this.updateEntryCountCallbacks.push(updateEntryCount);
    }

    private notifyUpdateEntryCount(count: number): void {
        this.updateEntryCountCallbacks.forEach(callback => callback(count));
    }

    public onChangeGroup(changeGroup: ChangeGroupCallback): void {
        this.changeGroupCallbacks.push(changeGroup);
    }

    protected notifyChangeGroup(group: SearchGroup): void {
        this.changeGroupCallbacks.forEach(callback => callback(group));
    }

    public onAppliedFilter(applyFilter: ApplyFilterCallback): void {
        this.applyFilterCallbacks.push(applyFilter);
    }

    private notifyApplyFilter(filters: Filters): void {
        this.applyFilterCallbacks.forEach(callback => callback(filters));
    }

    public onNewEntries(newEntries: NewEntriesCallback): void {
        this.newEntriesCallbacks.push(newEntries);
    }

    protected notifyNewEntries(group: SearchGroup): void {
        this.newEntriesCallbacks.forEach(callback => callback(group));
    }

    public onNewSearchPhrase(newSearchPhrase: NewSearchPhraseCallback): void {
        this.newSearchPhraseCallbacks.push(newSearchPhrase);
    }

    protected notifyNewSearchPhrase(): void {
        this.newSearchPhraseCallbacks.forEach(callback => callback(this.searchPhrase));
    }

    public async search(limitForActiveGroup: number = this.entriesPerPage): Promise<void> {
        await this.initialization();
        this.notifyNewSearchPhrase();

        if (this.emptyOnEmptySearchPhrase && !this.searchPhrase) {
            this.clearSearchInActiveGroup(limitForActiveGroup);
            this.inactiveGroups().forEach(group => this.clearSearchInInactiveGroup(group));
        } else {
            const activeGroupPromise = this.searchInActiveGroup(limitForActiveGroup).catch(EOP_ERRORS);
            this.inactiveGroups().forEach(group => {
                schedule(this.searchInInactiveGroup(group).catch(EOP_ERRORS)).as("eop-search-group");
            });
            await this.promises.decoratorFor("search")(activeGroupPromise);
        }
    }

    private async searchInActiveGroup(limit: number): Promise<void> {
        const results = await this.fetchEntries(this.sources(), this.searchPhrase, limit, 0, this.appliedFilters());
        this.updateActiveGroup(results, limit);
    }

    private clearSearchInActiveGroup(limit: number): void {
        this.updateActiveGroup(EMPTY_PAGE_RESPONSE, limit);
    }

    private updateActiveGroup(results: PageResponse, limit: number): void {
        this.updateFrom(results, limit);
        this.notifyUpdateFilter(this.applicableFilterData(), this.totalCount());
        this.notifyUpdateEntryCount(this.targetEntryCount());
        this.notifyNewEntries(this.activeGroup()!);
    }

    private async searchInInactiveGroup(group: SearchGroup): Promise<void> {
        const applicableFilters = group.applicableFiltersFrom(this.chosenFilters);
        const results = await this.fetchEntries(group.sources, this.searchPhrase, this.entriesPerPage, 0, applicableFilters);
        group.updateFrom(results, this.entriesPerPage);
    }

    private async clearSearchInInactiveGroup(group: SearchGroup): Promise<void> {
        group.updateFrom(EMPTY_PAGE_RESPONSE, this.entriesPerPage);
    }

    public async fetchMore(): Promise<void> {
        if (!this.hasMoreEntries()) {
            return;
        }

        const fetchPromise = this.fetchMoreEntries().catch(EOP_ERRORS);
        await this.promises.decoratorFor("more")(fetchPromise);
    }

    private async fetchMoreEntries(): Promise<void> {
        const response = await this.fetchEntries(this.sources(), this.searchPhrase, this.entriesPerPage, this.offset(), this.appliedFilters());
        this.extendWith(response);
        this.notifyUpdateEntryCount(this.targetEntryCount());
        this.notifyNewEntries(this.activeGroup()!);
    }

    public async requestActiveResultsCount(filters: Filters): Promise<number> {
        return this.fetchResultsCount(this.sources(), this.searchPhrase, this.applicableFiltersFrom(filters));
    }

    public async nextFilterChoice(filters: Filters): Promise<void> {
        await this.initialization();
        this.applyFilters(filters);
        await this.search();
    }

    public async removeFilter(category: string, value: string): Promise<void> {
        const values = this.chosenFilters.get(category) ?? [];
        values.removeAll(value);

        if (values.isEmpty()) {
            this.chosenFilters.delete(category);
        }

        await this.nextFilterChoice(this.chosenFilters);
    }

    public initSearchPhrase(searchPhrase: string | undefined): void {
        this.searchPhrase = searchPhrase ?? "";
    }

    public async nextSearchPhrase(searchPhrase: string | undefined): Promise<void> {
        this.searchPhrase = searchPhrase ?? "";
        await this.initialization();
        await this.search();
    }

    private updateFilters(): Promise<void>[] {
        return this.searchGroups
            .map(group => this.fetchFilterData(group.sources)
                .then(filterData => group.updateFilterDataFrom(filterData)));
    }

    public async initFilters(): Promise<void> {
        const allSources = this.searchGroups.flatMap(group => group.sources).distinct();
        const filterData = await this.fetchFilterData(allSources);
        const searchFilterData = this.mapFilterData(filterData);
        this.allFilterData = searchFilterData;
        this.notifyInitFilter(searchFilterData);
        this.applyFilters(this.chosenFilters);
    }

    private mapFilterData(filterData: FilterDataResponse[]): SearchFilterData[] {
        return filterData
            .filter(data => data.values.length !== 0)
            .map(data => ({
                id: "filter_" + data.id,
                label: data.label,
                filters: data.values
            }));
    }

    public initWith(name: string | number): void {
        const group = this.activate(name);
        if (group) {
            this.notifyChangeGroup(group);
            this.notifyUpdateFilter(this.applicableFilterData(), this.totalCount());
        }
    }

    public switchTo(name: string | number): void {
        const group = this.activate(name);
        if (group) {
            this.notifyChangeGroup(group);
            this.notifyUpdateEntryCount(this.targetEntryCount());
            this.notifyUpdateFilter(this.applicableFilterData(), this.totalCount());
        }
    }

    public addFilters(filters: Filters): void {
        filters.forEach((newValues, category) => {
            const values = this.chosenFilters.get(category) ?? [];
            values.push(...newValues);
            this.chosenFilters.set(category, values.distinct());
        });
        filters.forEach((filterValues, category) => {
            const selectedFilters = this.selectedFilters.get(category) ?? [];
            selectedFilters.push(...filterValues);
            this.selectedFilters.set(category, selectedFilters.distinct());
        });

        this.activeGroup()?.updateFilters(this.selectedFilters, this.chosenFilters);
    }

    public registerGroup(group: SearchGroup): void {
        this.searchGroups.push(group);
    }

    public activeGroup(): SearchGroup | null {
        return this.searchGroups.findFirst(group => group.active) ?? null;
    }

    public inactiveGroups(): SearchGroup[] {
        return this.searchGroups.filter(group => !group.active);
    }

    public getGroup(name: string): SearchGroup | null {
        return this.searchGroups.findFirst(group => group.name === name) ?? null;
    }

    private activate(id: string | number): SearchGroup | undefined {
        const newActiveGroup = isNumber(id)
            ? this.searchGroups[id]
            : this.searchGroups.findFirst(group => group.name === id);
        if (!newActiveGroup || newActiveGroup.active) {
            return undefined;
        }
        this.searchGroups.forEach(group => group.setActive(group === newActiveGroup));
        newActiveGroup.updateFilters(this.selectedFilters, this.chosenFilters);
        return newActiveGroup;
    }

    public focus(): void {
        this.activeGroup()?.focus();
    }

    public sources(): string[] {
        return this.activeGroup()?.sources ?? [];
    }

    public applicableFiltersFrom(filters: Filters): Filters {
        return this.activeGroup()?.applicableFiltersFrom(filters) ?? NO_FILTERS;
    }

    public hasMoreEntries(): boolean {
        return this.activeGroup()?.hasMoreEntries ?? false;
    }

    public offset(): number {
        return this.activeGroup()?.offset() ?? 0;
    }

    public updateFrom(results: PageResponse, limitForActiveGroup: number): void {
        this.activeGroup()?.updateFrom(results, limitForActiveGroup);
    }

    public extendWith(results: PageResponse): void {
        this.activeGroup()?.extendWith(results, this.entriesPerPage);
    }

    public applicableFilterData(): SearchFilterData[] {
        return this.activeGroup()?.applicableFilterData ?? [];
    }

    public totalCount(): number {
        return this.activeGroup()?.totalCount ?? 0;
    }

    public targetEntryCount(): number {
        return this.activeGroup()?.targetEntryCount ?? 0;
    }

    public appliedFilters(): Filters {
        return this.activeGroup()?.appliedFilters ?? NO_FILTERS;
    }

    private applyFilters(filters: Filters): void {
        this.chosenFilters = filters;
        const group = this.activeGroup();
        if (group) {
            group.updateFilters(this.selectedFilters, this.chosenFilters);
        }
        this.notifyApplyFilter(filters);
    }

    public hasSearchGroups(): boolean {
        return this.searchGroups.length > 0;
    }

    public getAllFilterData(): SearchFilterData[] | undefined {
        return this.allFilterData;
    }

    public getSelectedFilters(): Filters {
        return this.selectedFilters;
    }

    public async commitFilters(): Promise<void> {
        const nextFilterChoice = this.nextFilterChoice(this.selectedFilters);
        this.activeGroup()?.applyFilters(this.selectedFilters);
        await nextFilterChoice;
    }

    public hasUncommittedFilters(): boolean {
        return !isEqual(this.appliedFilters(), this.selectedFilters);
    }

    public async resetFilters(): Promise<void> {
        this.selectedFilters = new Map();
        if (this.appliedFilters().size === 0) {
            this.activeGroup()?.applyFilters(this.selectedFilters);
            return;
        }
        this.activeGroup()?.applyFilters(this.selectedFilters);
        await this.nextFilterChoice(new Map());
    }

    public revertFilters(): void {
        const appliedFilters = this.appliedFilters();
        this.selectedFilters = new Map();
        appliedFilters.forEach((values, category) => {
            this.selectedFilters.set(category, [...values]);
        });
        this.activeGroup()?.applyFilters(this.selectedFilters);
    }

    public addActiveFilter(filterCategory: string, filterValue: string): void {
        const filterValues = this.selectedFilters.get(filterCategory) ?? [];
        filterValues.push(filterValue);
        this.selectedFilters.set(filterCategory, filterValues);
    }

    public hasActiveFilter(filterCategory: string, filterValue: string): boolean {
        const filterValues = this.selectedFilters.get(filterCategory) ?? [];
        return filterValues.includes(filterValue);
    }

    public removeActiveFilter(filterCategory: string, filterValue: string): void {
        const filterValues = this.selectedFilters.get(filterCategory) ?? [];
        filterValues.removeAll(filterValue);
        if (filterValues.isEmpty()) {
            this.selectedFilters.delete(filterCategory);
        }
    }

    public toggleFilter(category: string, value: string): void {
        if (this.hasActiveFilter(category, value)) {
            this.removeActiveFilter(category, value);
        } else {
            this.addActiveFilter(category, value);
        }
    }

    public async allSources(): Promise<string[]> {
        await this.initialization();
        return this.searchGroups.flatMap(group => group.sources).distinct();
    }
}