import type {
    FeedEntriesByUrlsRequest,
    FeedEntriesRequest,
    FeedRelatedRequest,
    FiltersRequest,
    NewsCountRequest,
    NewsRequest,
    SiteSearchCountRequest,
    SiteSearchRequest,
    SuggestionRequest
} from "./requests";
import {autoRegister} from "../../container";
import {promiseFromJsonResponse} from "../../common/utils/promises";

export type PageResponse = {
    entries: ContentEntryResponse[];
    filterData: FilterDataResponse[];
    totalCount: number;
    newCount?: number;
};

export const EMPTY_PAGE_RESPONSE: PageResponse = {
    entries: [],
    filterData: [],
    totalCount: 0
};

export type FeedPageResponse = {
    entries: ContentEntryResponse[];
    totalCount: number;
};

type FilterResponse = {
    filterData: FilterDataResponse[];
};

type EntryCountResponse = {
    totalCount: number;
};

export type ContentEntryResponse = {
    title: string | null;
    description: string | null;
    headline: string | null;
    subheadline: string | null;
    newsType: string | null;
    principalKeyword: string;
    publishedDate: number;
    url: string | null;
    imageUrl: string | null;
    imageUrls: ImageUrlsResponse | null;
    imageAltText: string | null;
    keywords: string[];
    topics: TopicResponse[] | null;
    source: string[];
    contentType: string | null;
    mediaType: string | null;
    lightboxImageUrl: string | null;
    fileMetadata: FileMetadataResponse | null;
    disclaimer: DisclaimerResponse | null;
    isNew: boolean;
};

export type ImageUrlsResponse = {
    "7x4"?: string;
    "4x3"?: string;
    "3x4"?: string;
}

export type TopicResponse = {
    name: string;
    url: string;
};

export type FileMetadataResponse = {
    size: string | null;
    extension: string | null;
};

export type DisclaimerResponse = {
    headline: string | null;
    content: string | null;
};

export type FilterDataResponse = {
    id: string;
    label: string;
    matchingSources: string[];
    values: string[];
};

export type SuggestionResponse = {
    values: Array<string>;
};

@autoRegister()
export class FeedService {
    private readonly feedEntriesUrl: string;
    private readonly feedRelatedUrl: string;
    private readonly feedEntriesByUrlUrls: string;
    private readonly newsUrl: string;
    private readonly searchUrl: string;
    private readonly filtersUrl: string;
    private readonly suggestionUrl: string;

    public constructor() {
        this.feedEntriesUrl = "/enbw-eop/eop-app-feed-aggregator/rest/stable/feed/entries";
        this.feedRelatedUrl = "/enbw-eop/eop-app-feed-aggregator/rest/stable/feed/related";
        this.feedEntriesByUrlUrls = "/enbw-eop/eop-app-feed-aggregator/rest/stable/feed/by-urls";
        this.newsUrl = "/enbw-eop/eop-app-feed-aggregator/rest/stable/feed/news";
        this.searchUrl = "/enbw-eop/eop-app-feed-aggregator/rest/stable/feed/search";
        this.filtersUrl = "/enbw-eop/eop-app-feed-aggregator/rest/stable/feed/filters";
        this.suggestionUrl = "/enbw-eop/eop-app-feed-aggregator/rest/stable/feed/suggest";
    }

    public async entries(request: FeedEntriesRequest): Promise<FeedPageResponse> {
        const response = await fetch(`${this.feedEntriesUrl}/${request.lang}/${request.limit}?` + new URLSearchParams([
            ...(request.keywords.map(keyword => ["keyword", keyword])),
            ...(request.sources.map(source => ["source", source])),
            ["maxDescriptionLength", request.maxDescriptionLength.toString()],
            ["offset", request.offset.toString()],
            ...(request.excludedKeywords.map(keyword => ["exclude", keyword])),
            ["archived", "false"]
        ]).toString());
        return promiseFromJsonResponse(response);
    }

    public async related(request: FeedRelatedRequest): Promise<FeedPageResponse> {
        const response = await fetch(`${this.feedRelatedUrl}/${request.lang}/${request.limit}?` + new URLSearchParams([
            ...(request.sources.map(source => ["source", source])),
            ["maxDescriptionLength", String(request.maxDescriptionLength)],
            ["offset", String(request.offset)],
            ["url", request.relatedContentUrl]
        ]).toString());
        return promiseFromJsonResponse(response);
    }

    public async entriesByUrls(request: FeedEntriesByUrlsRequest): Promise<FeedPageResponse> {
        const response = await fetch(`${this.feedEntriesByUrlUrls}?` + new URLSearchParams([
            ...(request.urls.map(url => ["url", url])),
            ["maxDescriptionLength", String(request.maxDescriptionLength)]
        ]).toString());
        return promiseFromJsonResponse(response);
    }

    public async news(request: NewsRequest): Promise<PageResponse> {
        const response = await fetch(`${this.newsUrl}/${request.lang}/${request.limit}?` + new URLSearchParams([
            ["searchPhrase", request.searchPhrase],
            ...(request.sources.map(source => ["source", source])),
            ...(Array.from(request.filters.entries()).flatMap(entry => entry[1].map(value => [entry[0], value]))),
            ["maxDescriptionLength", String(request.maxDescriptionLength)],
            ["offset", String(request.offset)],
            ["lastVisit", String(request.lastVisitDate)],
            ["archived", String(request.archive)]
        ]).toString());
        return promiseFromJsonResponse(response);
    }

    public async newsCount(request: NewsCountRequest): Promise<EntryCountResponse> {
        const response = await fetch(`${this.newsUrl}/${request.lang}/0?` + new URLSearchParams([
            ["searchPhrase", request.searchPhrase],
            ...(request.sources.map(source => ["source", source])),
            ...(Array.from(request.filters.entries()).flatMap(entry => entry[1].map(value => [entry[0], value]))),
            ["archived", String(request.archive)]
        ]).toString());
        return promiseFromJsonResponse(response);
    }

    public async search(request: SiteSearchRequest): Promise<PageResponse> {
        const response = await fetch(`${this.searchUrl}/${request.lang}/${request.limit}?` + new URLSearchParams([
            ["searchPhrase", request.searchPhrase],
            ...(request.sources.map(source => ["source", source])),
            ...(Array.from(request.filters.entries()).flatMap(entry => entry[1].map(value => [entry[0], value]))),
            ["maxDescriptionLength", request.maxDescriptionLength.toString()],
            ["offset", String(request.offset)],
            ["archived", String(request.archive)]
        ]).toString());
        return promiseFromJsonResponse(response);
    }

    public async searchCount(request: SiteSearchCountRequest): Promise<EntryCountResponse> {
        const response = await fetch(`${this.searchUrl}/${request.lang}/0?` + new URLSearchParams([
            ["searchPhrase", request.searchPhrase],
            ...(request.sources.map(source => ["source", source])),
            ...(Array.from(request.filters.entries()).flatMap(entry => entry[1].map(value => [entry[0], value]))),
            ["archived", String(request.archive)]
        ]).toString());
        return promiseFromJsonResponse(response);
    }

    public async filters(request: FiltersRequest): Promise<FilterResponse> {
        const response = await fetch(`${this.filtersUrl}/${request.lang}?` + new URLSearchParams([
            ...(request.sources.map(source => ["source", source])),
            ["archived", String(request.archive)]
        ]).toString());
        return promiseFromJsonResponse(response);
    }

    public async suggestions(request: SuggestionRequest): Promise<SuggestionResponse> {
        const response = await fetch(`${this.suggestionUrl}/${request.lang}/${request.limit}?` + new URLSearchParams([
            ...(request.sources.map(source => ["source", source])),
            ["searchPhrase", String(request.searchPhrase)]
        ]).toString());
        return promiseFromJsonResponse(response);
    }
}