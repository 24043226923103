import type {ContactsRequest} from "./requests";
import {autoRegister} from "../../container";
import {promiseFromJsonResponse} from "../../common/utils/promises";

export type ContactsResponse = {
    contacts: Contact[];
};

export type ContactInfo = {
    phone: string;
    fax: string;
    mobile: string;
    email: string;
};

export type Address = {
    street: string;
    postalCode: string;
    city: string;
};

export type Contact = {
    title: string;
    infoBlock: string;
    imageUrl: string;
    imageAltText: string;
    link: string;
    corporationName: string;
    contactInfo: ContactInfo;
    address: Address;
    topics: string[];
};

@autoRegister()
export class ContactsService {
    private readonly contactsUrl: string;

    public constructor() {
        this.contactsUrl = "/enbw-eop/eop-app-feed-aggregator/rest/stable/contacts";
    }

    public async contacts(request: ContactsRequest): Promise<ContactsResponse> {
        const response = await fetch(`${this.contactsUrl}/${request.lang}?` + new URLSearchParams([
            ["searchPhrase", request.searchPhrase]
        ]).toString());
        return promiseFromJsonResponse(response);
    }
}