import {resolve} from "../../../container";
import {customElement, property} from "lit/decorators.js";
import {html, LitElement, type TemplateResult} from "lit";
import {EventBus} from "../../../common/eventBus";
import Styles from "./newsTag.lit.scss";
import {NEWS_TAG_EVENT} from "./constants";

@customElement("eop-news-tag")
export class EopNewsTag extends LitElement {

    public static readonly styles = Styles;

    @property()
    public text: string;

    public constructor(private eventBus: EventBus = resolve(EventBus)) {
        super();
    }

    public render(): TemplateResult {
        return html`
            <div class="tag-container" @click=${this.handleClick}>
                <div class="tag">
                    ${this.text}
                </div>
            </div>
        `;
    }

    private handleClick(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        this.eventBus.dispatchEvent(NEWS_TAG_EVENT, {text: this.text});
    }
}