import type {PropertyMap} from "../../common/utils/objects";
import FACEBOOK_ICON from "../../../../svgpictograms/socialicon_facebook.svg";
import TWITTER_ICON from "../../../../svgpictograms/socialicon_twitter.svg";
import LINKEDIN_ICON from "../../../../svgpictograms/socialicon_linkedin.svg";
import INSTAGRAM_ICON from "../../../../svgpictograms/socialicon_instagram.svg";
import BLOG_ICON from "../../../../svgpictograms/socialicon_blog.svg";
import YOUTUBE_ICON from "../../../../svgpictograms/socialicon_youtube.svg";
import PRESS_ICON from "../../../../svgpictograms/press.svg";

export const CONFIRMABLE_CHANNELS: string[] = [
    "youtube"
];

export const TAG_LIMIT = 3;

export const ICONS: PropertyMap = {
    facebook: FACEBOOK_ICON,
    twitter: TWITTER_ICON,
    linkedin: LINKEDIN_ICON,
    instagram: INSTAGRAM_ICON,
    blog: BLOG_ICON,
    youtube: YOUTUBE_ICON,
    press: PRESS_ICON
};

export type FeedChannel = {
    image?: string;
    imageUrl7x4?: string;
    imageUrl4x3?: string;
    imageUrl3x4?: string;
    width?: FeedChannelWidth;
}

export type FeedChannelWidth = "small" | "large" | "double";