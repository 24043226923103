import {customElement, property, query, state} from "lit/decorators.js";
import {html, LitElement, type PropertyValues, type TemplateResult} from "lit";
import {resolve} from "../../../container";
import Styles from "./searchTags.lit.scss";
import {UrlBuilder} from "../../../common/utils/url";
import {Resolution} from "../../../common/resolution";
import {Dictionary} from "../../../page/elements/dictionary";
import {classMap} from "lit/directives/class-map.js";
import {SEARCH_QUERY_PARAMETER_NAME} from "./constants";
import {ManagingResources} from "../../../common/lifetime";
import {UnLitElement} from "../../../common/elements";

const FEED_LESS_TAGS_KEY = "FEED_LESS_TAGS";
const FEED_MORE_TAGS_KEY = "FEED_MORE_TAGS";
const FEED_TAGS_TOGGLE_DOWN_CLASS = "toggle-link-down";
const FEED_TAGS_TOGGLE_UP_CLASS = "toggle-link-up";
const FEED_TAGS_EXPANDABLE_CLASS = "tags-expandable";
const FEED_TAGS_EXPANDED_CLASS = "tags-expanded";
const FEED_TAGS_INITIAL_CLASS = "initial";

const FEED_TAGS_MAX_VISIBLE_ROWS = 3;

@customElement("eop-search-tags")
export class EopSearchTags extends ManagingResources(LitElement) {

    @property({attribute: "target-url"})
    public targetUrl: string;
    @property({attribute: "tags", type: Array})
    public tags: string[];
    @state()
    private showAllFeedTags: boolean;

    public static readonly styles = Styles;

    public constructor(private resolution: Resolution = resolve(Resolution)) {
        super();
        this.showAllFeedTags = false;
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.resolution.onWindowResize(() => this.checkExpandability(), this);
    }

    public render(): TemplateResult {
        return html`
            <div class="tags">
                ${this.renderTagLinks()}
            </div>
            <div class="tags-toggle">
                ${this.renderTagsToggle()}
            </div>`;
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);

        this.checkExpandability();
    }

    private renderTagsToggle(): TemplateResult {
        const classes = {
            "toggle-link-up": this.showAllFeedTags,
            "toggle-link-down": !this.showAllFeedTags
        };
        const key = this.showAllFeedTags ? FEED_LESS_TAGS_KEY : FEED_MORE_TAGS_KEY;
        const label = Dictionary.of(this).translate(key);

        return html`
            <button type="button" class="link toggle-link ${classMap(classes)}" @click=${this.toggle}>${label}</button>
        `;
    }

    private toggle(): void {
        this.showAllFeedTags = !this.showAllFeedTags;
        this.classList.toggle(FEED_TAGS_EXPANDED_CLASS);
    }

    private renderTagLinks(): TemplateResult [] | null {
        if (this.tags.isEmpty()) {
            return null;
        }

        return this.tags.map(tag => this.renderAppliedFilterTag(tag));
    }

    private renderAppliedFilterTag(tag: string): TemplateResult {
        return html`
            <div class="tag-container">
                <div class="tag">
                    <a href=${this.href(tag)}>${tag}</a>
                </div>
            </div>
        `;
    }

    private href(tag: string): string {
        return new UrlBuilder(this.targetUrl ?? "")
            .withQueryParameter(SEARCH_QUERY_PARAMETER_NAME, tag)
            .build();
    }

    private checkExpandability(): void {
        const expandable = this.calculateRowsCount() > FEED_TAGS_MAX_VISIBLE_ROWS;
        this.classList.toggle(FEED_TAGS_EXPANDABLE_CLASS, expandable);
    }

    private calculateRowsCount(): number {
        const tag = this.renderRoot.querySelector<HTMLElement>(".tag-container");
        if (!tag) {
            return 0;
        }

        const tagHeight = tag.outerHeight({includeMargin: true});
        const tagContainerHeight = this.renderRoot.querySelector(".tags")!.scrollHeight;

        return Math.floor(tagContainerHeight / tagHeight);
    }
}

@customElement("eop-search-tags-static")
export class EopSearchTagsStatic extends ManagingResources(UnLitElement) {

    @query("button")
    private toggleButton: HTMLElement;
    @query("button span")
    private toggleButtonText: HTMLElement;


    public constructor(private resolution: Resolution = resolve(Resolution)) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();
        const dictionary = Dictionary.of(this);
        const activeLabel = dictionary.translate(FEED_LESS_TAGS_KEY);
        const inactiveLabel = dictionary.translate(FEED_MORE_TAGS_KEY);

        this.resolution.onWindowResize(() => this.checkExpandability(), this);
        this.checkExpandability();
        this.classList.remove(FEED_TAGS_INITIAL_CLASS);

        let showAllFeedTags = false;

        this.toggleButtonText.innerHTML = inactiveLabel;

        this.toggleButton.addEventListener("click", () => {
            showAllFeedTags = !showAllFeedTags;

            this.toggleButtonText.innerHTML = showAllFeedTags ? activeLabel : inactiveLabel;

            this.toggleButton.classList.toggle(FEED_TAGS_TOGGLE_DOWN_CLASS, !showAllFeedTags);
            this.toggleButton.classList.toggle(FEED_TAGS_TOGGLE_UP_CLASS, showAllFeedTags);

            this.classList.toggle(FEED_TAGS_EXPANDED_CLASS);
            this.toggleButton.blur();
        });
    }

    private checkExpandability(): void {
        const expandable = this.calculateRowsCount() > FEED_TAGS_MAX_VISIBLE_ROWS;
        this.classList.toggle(FEED_TAGS_EXPANDABLE_CLASS, expandable);
    }

    private calculateRowsCount(): number {
        const tags = this.querySelectorAll("eop-search-tag-static");

        if (tags.length === 0) {
            return 0;
        }

        const tagHeight = tags.item(0).clientHeight;
        const tagContainerHeight = this.querySelector(".tags")!.scrollHeight;

        return Math.floor(tagContainerHeight / tagHeight);
    }
}

@customElement("eop-search-tag-static")
export class EopSearchTagStatic extends UnLitElement {
    @property({attribute: "href"})
    private href: string;
    @property({attribute: "label"})
    private label: string;
    @query("a")
    private tagLink: HTMLAnchorElement;

    public connectedCallback(): void {
        super.connectedCallback();

        this.tagLink.href = new UrlBuilder(this.href ?? "")
            .withQueryParameter(SEARCH_QUERY_PARAMETER_NAME, this.label)
            .build();
    }
}