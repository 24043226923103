import {autoRegister, resolve} from "../../../container";
import {LocalStorage} from "../../../common/clientStorage";

const ONE_HOUR = 60 * 60 * 1000;
const ONE_MONTH = 30 * 24 * ONE_HOUR;

const CLIENT_STORAGE_SESSION_KEY = "newsroom";
const CLIENT_STORAGE_HISTORY_KEY = "newsroom-history";
const SESSION_TIMEOUT = 12 * ONE_HOUR;
const MAX_SESSION_AGE = 6 * ONE_MONTH;
const STORAGE_EXPIRY = 12 * ONE_MONTH;

export type SessionData = {
    currentSession: number;
    lastSession: number;
};

export type HistoryData = {
    [visitedUrl: string]: {};
};

function now(): number {
    return new Date().getTime();
}

@autoRegister()
export class VisitRecord {

    public constructor(private storage: LocalStorage = resolve(LocalStorage)) {
    }

    public getLastSessionDate(): number {
        const sessionData = this.retrieveSessionData();
        if (!sessionData) {
            this.updateSessionDataWith(now());
            return this.limitDate(now());
        }

        const currentSession = sessionData.currentSession;
        if (this.isExpired(currentSession)) {
            this.updateSessionDataWith(currentSession);
            return this.limitDate(currentSession);
        }

        return this.limitDate(sessionData.lastSession);
    }

    public retrieveSessionData(): SessionData | undefined {
        return this.storage.fetch<SessionData>(CLIENT_STORAGE_SESSION_KEY);
    }

    public updateSessionDataWith(date: number, current: number = now()): void {
        const sessionData = {
            currentSession: current,
            lastSession: date
        };
        const untilStorageExpiry = {until: current + STORAGE_EXPIRY};
        this.storage.save(CLIENT_STORAGE_SESSION_KEY, sessionData, untilStorageExpiry);
    }

    private isExpired(date: number): boolean {
        return (now() - date) >= SESSION_TIMEOUT;
    }

    private limitDate(date: number): number {
        return Math.max(date, now() - MAX_SESSION_AGE);
    }

    public addVisitedURL(url: string): void {
        const untilOld = {until: now() + 2 * SESSION_TIMEOUT};
        this.storage.saveInPath(CLIENT_STORAGE_HISTORY_KEY, [url], {}, untilOld);
    }

    public getVisitedURLs(): string[] {
        const historyData = this.storage.fetch<HistoryData>(CLIENT_STORAGE_HISTORY_KEY);
        return historyData
            ? Object.keys(historyData)
            : [];
    }
}