import type {ContentEntryResponse, DisclaimerResponse, FileMetadataResponse, ImageUrlsResponse, TopicResponse} from "./feedService";
import type {PropertyMap} from "../../common/utils/objects";
import type {FeedChannel, FeedChannelWidth} from "./feedCommons";
import {falsyToUndefined} from "../../common/utils/basics";

export class FeedEntry {

    private channel: FeedEntryChannel;
    public recentlyVisited?: boolean;

    public constructor(public readonly data: FeedEntryData, private sources: string[] = []) {
        this.channel = DEFAULT_FEED_ENTRY_CHANNEL;
    }

    public static fromResponse(response: ContentEntryResponse): FeedEntry {
        return new FeedEntry(mapResponseToFeedEntryData(response), response.source);
    }

    public inChannelContext(allChannels: PropertyMap<FeedChannel>): this {
        const source = this.sources.findFirst(s => Object.keys(allChannels).includes(s)) ?? "";
        this.channel = mapChannel(allChannels[source]);
        return this;
    }

    public beingRecentlyVisited(value: boolean): this {
        this.recentlyVisited = value;
        return this;
    }

    public getImageUrl(): string {
        return this.data.imageUrl || this.channel?.imageUrl || "";
    }

    public getImageUrlFor7x4Purpose(): string {
        return this.getImageUrlForAspectRatio(urls => urls?.src_7x4);
    }

    public getImageUrlFor4x3Purpose(): string {
        return this.getImageUrlForAspectRatio(urls => urls?.src_4x3);
    }

    public getImageUrlFor3x4Purpose(): string {
        return this.getImageUrlForAspectRatio(urls => urls?.src_3x4);
    }

    private getImageUrlForAspectRatio(selector: (imageUrls: ImageUrls | undefined) => string | undefined): string {
        return selector(this.data.imageUrlsPerAspectRatio)
            || this.data.imageUrl
            || selector(this.channel?.imageUrlsPerAspectRatio)
            || this.channel?.imageUrl
            || "";
    }

    public getTileStyle(): TileVariant {
        return this.channel.tileVariant;
    }

    public isExternalLink(): boolean {
        return this.data.newsType === "";
    }
}

function mapResponseToFeedEntryData(entry: ContentEntryResponse): FeedEntryData {
    return {
        title: falsyToUndefined(entry.title),
        description: falsyToUndefined(entry.description),
        headline: falsyToUndefined(entry.headline),
        subHeadline: falsyToUndefined(entry.subheadline),
        url: entry.url ?? "",
        publishedDate: entry.publishedDate,
        imageUrl: falsyToUndefined(entry.imageUrl),
        imageUrlsPerAspectRatio: mapImageUrls(entry.imageUrls),
        imageAltText: entry.imageAltText ?? "",
        contentType: entry.contentType ?? "",
        mediaType: entry.mediaType ?? "",
        newsType: entry.newsType ?? "",
        principalKeyword: entry.principalKeyword,
        topics: mapTopics(entry.topics),
        fileMetadata: mapFileMetadata(entry.fileMetadata),
        disclaimer: mapDisclaimer(entry.disclaimer),
        lightboxImageUrl: falsyToUndefined(entry.lightboxImageUrl),
        isNew: entry.isNew
    };
}

function mapChannel(channel: FeedChannel | undefined): FeedEntryChannel {
    if (!channel) {
        return {
            tileVariant: TileVariant.SMALL
        };
    }

    return {
        imageUrl: falsyToUndefined(channel.image),
        imageUrlsPerAspectRatio: {
            src_7x4: falsyToUndefined(channel.imageUrl7x4),
            src_4x3: falsyToUndefined(channel.imageUrl4x3),
            src_3x4: falsyToUndefined(channel.imageUrl3x4)
        },
        tileVariant: channel.width ? mapTileVariant(channel.width) : TileVariant.SMALL
    };
}

function mapTileVariant(variant: FeedChannelWidth): TileVariant {
    switch (variant) {
        case "small":
            return TileVariant.SMALL;
        case "large":
            return TileVariant.LARGE;
        case "double":
            return TileVariant.DOUBLE;
        default:
            return TileVariant.SMALL;
    }
}

function mapImageUrls(responseUrls: ImageUrlsResponse | null): ImageUrls | undefined {
    if (!responseUrls) {
        return undefined;
    }

    return {
        src_7x4: falsyToUndefined(responseUrls["7x4"]),
        src_4x3: falsyToUndefined(responseUrls["4x3"]),
        src_3x4: falsyToUndefined(responseUrls["3x4"])
    };
}

function mapTopics(topicsResponse: TopicResponse[] | null): FeedEntryTopic[] {
    if (!topicsResponse) {
        return [];
    }

    return topicsResponse.map(topic => ({name: topic.name, url: topic.url}));
}

function mapFileMetadata(metadataResponse: FileMetadataResponse | null): FeedEntryFileMetadata | undefined {
    if (!metadataResponse) {
        return undefined;
    }

    return {
        size: metadataResponse.size ?? "",
        extension: metadataResponse.extension ?? ""
    };
}

function mapDisclaimer(disclaimerResponse: DisclaimerResponse | null): FeedEntryDisclaimer | undefined {
    if (!disclaimerResponse) {
        return undefined;
    }

    return {
        headline: disclaimerResponse.headline ?? "",
        content: disclaimerResponse.content
    };
}

export type FeedEntryData = {
    title?: string;
    description?: string;
    headline?: string;
    subHeadline?: string;
    url: string;
    publishedDate: number;
    imageUrl?: string;
    imageUrlsPerAspectRatio?: ImageUrls;
    imageAltText: string;
    contentType: string;
    mediaType: string;
    newsType: string;
    principalKeyword: string;
    topics: FeedEntryTopic[];
    fileMetadata?: FeedEntryFileMetadata;
    disclaimer?: FeedEntryDisclaimer;
    lightboxImageUrl?: string;
    isNew: boolean;
}

export type ImageUrls = {
    src_7x4?: string;
    src_4x3?: string;
    src_3x4?: string;
}

type FeedEntryDisclaimer = {
    headline: string;
    content: any;
}

type FeedEntryFileMetadata = {
    size: string;
    extension: string;
}

export type FeedEntryTopic = {
    name: string;
    url: string;
}

export enum TileVariant {
    SMALL = "small-tile",
    LARGE = "large-tile",
    DOUBLE = "double-tile"
}

type FeedEntryChannel = {
    imageUrl?: string;
    imageUrlsPerAspectRatio?: ImageUrls;
    tileVariant: TileVariant;
}

const DEFAULT_FEED_ENTRY_CHANNEL: FeedEntryChannel = {
    tileVariant: TileVariant.SMALL
};