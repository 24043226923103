import {customElement} from "lit/decorators.js";
import {SearchTile} from "../searchTile";
import Styles from "./siteSearchTile.lit.scss";
import {html, type TemplateResult} from "lit";
import {keyed} from "lit/directives/keyed.js";

@customElement("eop-site-search-tile")
export class EopSiteSearchTile extends SearchTile {

    public static readonly styles = Styles;

    public render(): TemplateResult {
        return html`${keyed(this.entry.data.url + this.groupName, this.renderTile())}`;
    }

    protected renderImageElement(): null {
        return null;
    }

    protected renderTopicLinks(): null {
        return null;
    }

    protected showDescription(): boolean {
        return true;
    }

    protected headlineText(): string {
        return this.entry.data.title ?? "---";
    }

    protected subHeadlineText(): string {
        return this.entry.data.description ?? "---";
    }
}