import {html, LitElement, type TemplateResult} from "lit";
import {customElement, property, state} from "lit/decorators.js";
import Styles from "./newsSearchPreviewTiles.lit.scss";
import {SearchFacade} from "../searchFacade";
import {resolve} from "../../../../container";
import {NewsSearchBackend} from "../newsSearchBackend";
import type {FeedEntry} from "../../feedEntry";
import type {SearchTileData} from "../searchGroup";


@customElement("eop-news-search-preview-tiles")
export class EopNewsSearchPreviewTiles extends LitElement {

    public static readonly styles = Styles;

    @property()
    public resultsPageUrl: string;
    @state()
    private tiles: SearchTileData[];

    public constructor(private searchFacade: SearchFacade = resolve(SearchFacade)) {
        super();
        searchFacade.registerBackend(new NewsSearchBackend());
        this.tiles = [];
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.searchFacade.onChangeGroup(group => this.tiles = this.mapEntries(group.entries, group.name));
        this.searchFacade.onNewEntries(group => this.tiles = this.mapEntries(group.entries, group.name));
    }

    public render(): TemplateResult {
        return html`
            <div class="tiles">${(this.renderTiles())}</div>`;
    }

    private renderTiles(): TemplateResult[] {
        return this.tiles.map(tileData => html`
            <eop-news-search-preview-tile
                    .entry=${tileData.entry}
                    .groupName=${tileData.groupName}
                    .resultsPageUrl=${this.resultsPageUrl}
                    class="tile ${tileData.entry.getTileStyle()}"
            ></eop-news-search-preview-tile>`);
    }

    private mapEntries(entries: FeedEntry[], groupName: string): SearchTileData[] {
        return entries.map(entry => ({entry: entry, groupName: groupName}));
    }
}