import {html, LitElement, type TemplateResult} from "lit";
import {customElement} from "lit/decorators.js";
import Styles from "./lightboxDialog.lit.scss";

@customElement("eop-lightbox-dialog")
export class LightboxDialog extends LitElement {

    public static readonly styles = Styles;

    public constructor(
        private headline: string,
        private subheadline: string,
        private url: string,
        private lightboxImageUrl: string,
        private mediaType: string,
        private imgAltText: string,
        private buttonLabel: string
    ) {
        super();
    }

    public render(): TemplateResult {
        return html`
            <div class=lightbox-dialog>
                <h3>${this.headline}</h3>
                ${this.renderSubheadline()}
                <a href=${this.url} download class="lightbox-download secondary" data-eventelement="lightbox-download">${this.buttonLabel}</a>
                <div class=download-lightbox-image>
                    ${this.renderImageElement()}
                </div>
            </div>
        `;
    }

    private renderImageElement(): TemplateResult {
        if (this.mediaType === "responsiveImage") {
            return html`
                <eop-responsive-image
                        .imageSrc=${this.lightboxImageUrl}
                        .imageAlt=${this.imgAltText}
                        intrinsic-aspect-ratio
                ></eop-responsive-image>
            `;
        }
        return html`
            <eop-plain-image
                    src=${this.lightboxImageUrl}
                    alt=${this.imgAltText}
                    class="eop-image"
            ></eop-plain-image>
        `;
    }

    private renderSubheadline(): TemplateResult | null {
        if (!this.subheadline) {
            return null;
        }

        return html`
            <div class="download-lightbox-description">${this.subheadline}</div>
        `;
    }
}

export namespace LightboxDialogFactory {
    export function create(
        headline: string,
        subheadline: string,
        url: string,
        lightboxImageUrl: string,
        mediaType: string,
        imgAltText: string,
        buttonLabel: string
    ): LightboxDialog {
        return new LightboxDialog(headline, subheadline, url, lightboxImageUrl, mediaType, imgAltText, buttonLabel);
    }
}