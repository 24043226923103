import {customElement} from "lit/decorators.js";
import {SearchFacade} from "./searchFacade";
import {resolve} from "../../../container";
import {HorizontalOverflow, HorizontalOverflowInstaller} from "../../../page/elements/horizontalOverflow";
import {UnLitElement} from "../../../common/elements";
import {ResizeObserverFactory} from "../../../common/observation";

@customElement("eop-search-group-tabs")
export class EopSearchGroupTabs extends UnLitElement {

    private resizeObserver: ResizeObserver;
    private horizontalOverflow: HorizontalOverflow;

    public constructor(
        private searchFacade: SearchFacade = resolve(SearchFacade),
        private horizontalOverflowInstaller: HorizontalOverflowInstaller = resolve(HorizontalOverflowInstaller),
        private resizeObserverFactory: ResizeObserverFactory = resolve(ResizeObserverFactory)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.resizeObserver = this.resizeObserverFactory.create(() => this.focusActiveGroup());
        this.resizeObserver.observe(this);
        this.horizontalOverflow = this.horizontalOverflowInstaller.installOn(this);
    }

    public disconnectedCallback(): void {
        this.horizontalOverflow.uninstall();
        this.resizeObserver.disconnect();
        super.disconnectedCallback();
    }

    private focusActiveGroup(): void {
        this.searchFacade.focus();
    }
}