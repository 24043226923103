import {Dictionary} from "../../../page/elements/dictionary";
import {SearchFacade} from "./searchFacade";
import {TemplateModel} from "../../../common/template";
import {resolve} from "../../../container";


export class EopSearchFilterTags extends HTMLElement {

    private appliedFiltersLabel: Text;

    public constructor(private searchFacade: SearchFacade = resolve(SearchFacade)) {
        super();
    }

    public connectedCallback(): void {
        this.searchFacade.onChangeGroup(() => this.updateTags());
        this.searchFacade.onAppliedFilter(() => this.updateTags());
        this.appliedFiltersLabel = new Text(Dictionary.of(this).translate("CONTENT_SEARCH_RESULTS_FILTERS") + " ");
    }

    private updateTags(): void {
        const tags = Array.from(this.searchFacade.appliedFilters().entries())
            .map(([category, values]) => this.buildTagRecord(category, values));
        if (tags.length === 0) {
            this.replaceChildren();
            this.style.display = "none";
        } else {
            const newChildren = tags.prepend(this.appliedFiltersLabel);

            this.replaceChildren(...newChildren);
            this.style.display = "";
        }
    }

    private buildTagRecord(category: string, values: string[]): Node {
        const record = document.createElement("div");
        record.classList.add("applied-filter-block");
        const children = values.map(value => new EopSearchFilterTag(category, value));
        record.append(...children);
        return record;
    }

}

export class EopSearchFilterTag extends HTMLElement {
    public constructor(
        private category: string,
        private value: string,
        private searchFacade: SearchFacade = resolve(SearchFacade)
    ) {
        super();
    }

    public connectedCallback(): void {
        const content = new TemplateModel(`<span class="reverse-background">${this.value}</span>`).asElement()!;
        this.replaceChildren(content);
        this.addEventListener("click", () => this.searchFacade.removeFilter(this.category, this.value));
    }

}

customElements.define("eop-search-filter-tags", EopSearchFilterTags);
customElements.define("eop-search-filter-tag", EopSearchFilterTag);
