import {customElement, property} from "lit/decorators.js";
import {html, LitElement, type TemplateResult} from "lit";
import Styles from "./filterValue.lit.scss";
import {eopCustomEvent} from "../../../../common/eventBus";
import {FILTER_TOGGLE_EVENT} from "../constants";

@customElement("eop-search-filter-value")
export class EopSearchFilterValue extends LitElement {

    @property({type: Boolean, reflect: true})
    private active: boolean = false;

    public static readonly styles = Styles;

    public constructor(public category: string, private value: string) {
        super();
    }

    public render(): TemplateResult {
        return html`
            <div class="filter-value" @click=${this.toggleFilter}>${this.value}</div>`;
    }

    private toggleFilter(): void {
        this.active = !this.active;
        this.dispatchEvent(filterToggleEvent(this.category, this.value));
    }
}

export type FilterToggleEvent = CustomEvent<{ category: string; value: string }>;

export function filterToggleEvent(category: string, value: string): FilterToggleEvent {
    return eopCustomEvent(FILTER_TOGGLE_EVENT, {category: category, value: value});
}