export const SEARCH_QUERY_PARAMETER_NAME = "s";
export const ENTRY_COUNT_QUERY_PARAMETER_NAME = "entries";
export const TAB_QUERY_PARAMETER_NAME = "tab";

export const NEW_SEARCH_PHRASE_EVENT = "newSearchPhrase";
export const SEARCH_TILES_IN_VIEWPORT_EVENT = "searchTilesInViewport";
export const NEWS_TAG_EVENT = "newsTag";
export const FILTER_TOGGLE_EVENT = "filterToggle";


export const FETCH_SUGGESTIONS_DEBOUNCE = 400;

// keep this constant in sync with the one in the feed-aggregator and feed-aggregator-data application
export const MIN_SUGGESTION_LENGTH = 3;